import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import SEO from 'components/seo';
import AppLayout from 'app/AppLayout/AppLayout';
import Error from 'components/Error/Error';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const classes = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 3),
      },
    },
  }))();

  return (
    <AppLayout>
      <SEO title={t('routes.404.meta.title')} />
      <Container className={classes.root}>
        <Error title={t('errors.notFound')} type="notFound" />
      </Container>
    </AppLayout>
  );
};

export default NotFoundPage;
