import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import networkImg from 'assets/img/error-network.png';
import notFoundImg from 'assets/img/error-not-found.png';
import unexpectedImg from 'assets/img/error-unexpected.png';
import useStyles from './Error.styles';

const CustomError = ({ description, title, type }) => {
  const classes = useStyles();

  const getImg = () => {
    if (type === 'unexpected') {
      return unexpectedImg;
    }
    return type === 'notFound' ? notFoundImg : networkImg;
  };

  return (
    <Box className={classes.container}>
      <center>
        <img alt={`${type} error`} className={classes.img} loading="lazy" src={getImg()} />
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        {description && (
          <Typography variant="caption">
            {description}
          </Typography>
        )}
      </center>
    </Box>
  );
};

CustomError.defaultProps = {
  description: null,
  type: 'unexpected',
};

CustomError.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['network', 'notFound', 'unexpected']),
};

export default CustomError;
